.computation-div-header {
    text-align: center;
    margin-top: 2%;
    color: #7B8D93;
    font-size: 46px;
}

.duration-holding-div {
    display: flex;
    margin-left: 7%;
    width: 38%;
}

/**/

.u-input-c {
    width: 251%;
}

.input-div-part-1 {
    display: block;
}

.input-div {
    margin-top: 2%;
}

.inner-div {
    display: flex;
    margin-top: 7%;
}

.u-dd {
    margin-left: 1%;
}

.main-drops {
    display: inline-flex;
    margin-left: 23.5%;
}

.singularity-b {
    margin-left: 11.5%;
}

.singularity-c {
    margin-left: 11%;
}

.input-div-holding {
    display: flex;
    text-align: center;
    margin-left: 23.7%;
}

.com-part {
    display: flex;
    margin-top: 2%;
}

.my-custom-dd {
    outline: none;
    width: 209px;
    border: none;
    border-bottom: 1px solid #b4b6ae;
}

.my-custom-u-dd {
    border: none;
    border-bottom: 1px solid #b4b6ae;
    outline: none;
}

.computation-input {
    outline: none;
    width: 100%;
    height: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.computation-input-unit {
    outline: none;
    width: 60%;
    height: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

button, input {
    overflow: visible;
}

.input-div-part-2 {
    margin-left: 6.5%;
}

.input-div-part-3 {
    margin-left: 6%;
    width: 21%;
}

.com-part {
    margin-left: 43.7%;
}

.u-input-cc-2 {
    margin-left: 23%;
}

.submit-area {
    text-align: center;
    margin-top: 2%;
}

.compute-buttn {
    margin-left: 1%;
}

.result-field {
    text-align: center;
    margin-top: 1%;
}

.computation-field {
    margin-top: 2%;
}

.computation-input::placeholder {
    font-size: 15px;
    color: black;
}

.result-val {
    margin-left: 1%;
    outline: none;
}

.result-clear-buttn {
    margin-left: 1%;
}

.compute-buttn {
    background-color: #6094b7;
    border: none;
    border-radius: 2px;
    width: 150px;
    height: 40px;
    color: #fff;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}

.result-clear-buttn {
    background-color: #5ebbc5;
    border: none;
    border-radius: 2px;
    width: 100px;
    height: 40px;
    color: #fff;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}

.result-val {
    border: 10px solid #eff1e6;
    background: #eff1e6;
    width: 180px;
    height: 40px;
    outline: none;
    cursor: not-allowed;
}

.group-hold {
    text-align: center;
    border-radius: 5px;
    border: 1px solid rgb(140, 140, 140);
    padding: 2%;
    margin-top: 2%;
}

.u-input-ccc-1 {
    margin-left: 9%;
    width: 110%;
}

.u-input-ccc {
    margin-left: 2%;
    width: 98%;
    display: flex;
}

input:disabled {
    background-color: white;
    border-bottom-color: transparent;
}
input:disabled::placeholder {
    color: rgba(0,0,0,0.6);
}
select:disabled{
    border-bottom-color: transparent;
}

/**/
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
    .computation-div{
        width: 97%;
    }
    .computation-title {
        font-size: 20px;
    }

    .main-drops {
        display: block;
        width: fit-content;
        margin-left: 10%;
    }

    .input-div-part-1 {
        display: block;
        width: 60%;
    }

    .input-div-holding {
        display: block;
        text-align: center;
        margin-left: 0%;
    }

    .group-hold {
        border: none;
        padding: 0px;
    }

    .u-input {
        width: 100%;
    }

    .u-input-bb {
        width: 86%;
    }

    .input-div-part-1 {
        display: block;
        margin-left: 10%;
        width: 82%;
    }

    .u-input-group-1-1 {
        width: 84%;
    }

    .u-input-group-1-2 {
        width: 84%;
    }

    .input-div-part-2 {
        width: 80%;
        margin-left: 10%;
    }

    .my-custom-dd {
        width: 182%;
        margin-top: 7%;
    }

    .singularity-b {
        margin-left: 0%;
    }

    .singularity-c {
        margin-left: 0%;
    }

    .input-div-part-3 {
        margin-left: 10%;
        width: 89%;
    }

    .u-input-c {
        width: 88%;
    }

    .u-input-cc {
        width: 74%;
    }

    .u-input-ccc {
        margin-left: 0%;
        width: 74%;
    }
    #cloudCover{
        margin-top: 0%;
    }
    #energyExpKCalUnit{
        margin-left: 75%;
    }
    #input-cc{
        width: 88%;
    }
    .com-part {
        display: block;
        margin-top: 2%;
        margin-left: 10%;
    }

    .inner-com-part-2 {
        width: 49%;
    }

    .u-input-ccc-1 {
        width: 89%;
        margin-top: 4%;
        margin-left: 0%;
    }

    #duration {
        margin-top: 1%;
    }

    .submit-area {
        text-align: center;
        margin-top: 5%;
    }

    .result-field {
        text-align: center;
        margin-top: 5%;
    }

    .duration-holding-div {
        display: flex;
        margin-left: 0%;
        width: 100%;
    }
    .inner-div {
        display: flex;
        margin-top: 2%;
    }
}

@media only screen and (min-device-width: 411px) and (max-device-width: 731px) {
    .my-custom-dd {
        width: 197%;
        margin-top: 7%;
    }
}

@media only screen and (min-device-width: 1500px) {
    .singularity-b {
        margin-left: 12.5%;
    }

}

/* For 1024 Resolution */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

}