.main-login-div{
    margin: 0%;
}

.outer-login-pane{
    border:2px solid black;
    border-radius: 2%;
    width: 364px;
    height: 452px;
    margin-top: 5%;
    margin-left: 36.5%;
    text-align: center;
    background-color: #FFFBFC;
}
.login-input{
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    height: 40px;
    width: 324px;
    outline: none;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid rgba(0,0,0,.2);
}
::placeholder{
    color: #9DA6A9;
    font-size: 18px;
}
.login-pane{
    margin-top: 15%;
}
.logo-login-page{
    width: 25%;
    height: 25%;
}
.logo-div{
    margin-top: 5%;
}
.login-buttn{
    background-color: #6094B7;
    margin-top: 10%;
    width: 279px;
    height: 46px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    outline: none;
}
.pop-up-label{
    font-size: 12px;
}
button:disabled{
    background-color: rgba(96, 148, 183, 0.5);
    cursor: not-allowed;
}
/*Small Devices screen upto 320px */
@media (width: 320px ) and (height: 557px){
    .outer-login-pane{
        width: 465px;
        height: 562px;
        margin-top: 37%;
        margin-left: 4%;
    }
    .login-input{
        margin-top: 3.5%;
        margin-bottom: 3.5%;
        height: 40px;
        width: 431px;
    }
    .login-buttn{
        margin-top: 10%;
        width: 431px;
        height: 50px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
    .help-outer-a {
        margin-top: 5%;
        margin-left: 140%;
    }
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 90%;
        height: 452px;
        margin-top: 14%;
        margin-left: 6%;
        text-align: center;
        background-color: #FFFBFC;
    }
}
@media only screen and (min-device-width: 420px) and (max-device-width: 440px){
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 364px;
        height: 452px;
        margin-top: 14%;
        margin-left: 8%;
        text-align: center;
        background-color: #FFFBFC;
    }
}
@media only screen and (min-device-width: 450px) and (max-device-width: 470px){
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 364px;
        height: 452px;
        margin-top: 14%;
        margin-left: 10%;
        text-align: center;
        background-color: #FFFBFC;
    }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 600px){
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 364px;
        height: 452px;
        margin-top: 14%;
        margin-left: 12%;
        text-align: center;
        background-color: #FFFBFC;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
and (orientation: landscape){
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 364px;
        height: 452px;
        margin-top: 10%;
        margin-left: 22%;
        text-align: center;
        background-color: #FFFBFC;
    }
}

@media (max-width: 768px ) and (min-width: 720px){
    .outer-login-pane {
        border: 2px solid black;
        border-radius: 2%;
        width: 364px;
        height: 452px;
        margin-top: 20%;
        margin-left: 27%;
        text-align: center;
        background-color: #FFFBFC;
    }
}