.outer-reg-pane{
    border:2px solid black;
    border-radius: 2%;
    width: 700px;
    height: 452px;
    margin-top: 5%;
    margin-left: 27%;
    background-color: #FFFBFC;
    display: flex;
}
.reg-input{
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    height: 40px;
    width: 250px;
    outline: none;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 2px solid rgba(0,0,0,.2);
}
::placeholder{
    color: #9DA6A9;
    font-size: 18px;
}
.reg-pane {
    margin-top: 15%;
    margin-left: 6%;
}
.reg-pane2 {
    margin-top: 15%;
    margin-left: 13%;
}
.logo-reg-page{
    width: 25%;
    height: 25%;
}
.logo-div{
    margin-top: 5%;
}
.reg-buttn{
    background-color: #6094B7;
    margin-top: 10%;
    width: 279px;
    height: 46px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    outline: none;
}
.dd-area{
    height: fit-content;
    margin-top: 33%;
    /* margin-right: 0%; */
    margin-left: -60%;
}
.add-buttn{
    margin-top: 45%;
    /* margin-right: 10%; */
    margin-left: -38%;
}

/*Small Devices screen upto 320px */
@media (width: 320px ) and (height: 557px){
    .outer-reg-pane{
        width: 465px;
        height: 562px;
        margin-top: 37%;
        margin-left: 4%;
    }
    .reg-input{
        margin-top: 3.5%;
        margin-bottom: 3.5%;
        height: 40px;
        width: 431px;
    }
    .reg-buttn{
        margin-top: 10%;
        width: 431px;
        height: 50px;
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
    .outer-reg-pane{
        margin-top: 21%;
        margin-left: 3.5%;
        width: 470px;
        height: 490px;
        display: block;
    }
    .reg-input{
        width: 400px;
    }
    .reg-pane2 {
        margin-top: 0%;
        margin-left: 6%;
    }
    .dd-area {
        height: fit-content;
        margin-top: 0%;
        margin-left: 6%;
    }
    .add-buttn {
        margin-top: 0%;
        margin-left: 20%;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
and (orientation: landscape){

}

@media (max-width: 768px ) and (min-width: 720px) {

}