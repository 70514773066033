.main-div-appBar{
    background-color: #6094B7;
    width: 100%;
    height: 75px;
    padding-top: 1%;
    margin-right: 0%;
}
.nav-heading{
    color: white;
    font-size: 32px;
    margin: 0%;
    padding-top: 1%;
    font-weight: bold;
}
.logo-img{
    width: 6%;
    height: 6%;
    margin-right: 2%;
}
.inner-app-bar{
    display: inline-flex;
    margin-left: 3%;
    width: 43%;
}
.help-outer{
    width: 100%;
    padding-top: 1%;
    padding-left: 80%;
    margin-left: 100%;
}
.help-icon{
    /*icon class*/
}
.logout{
    padding-top: 1.5%;
    padding-left: 2%;
    margin-left: 1%;
}
.logout-link{
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
}

/*Small Devices screen upto 320px */
@media (width: 320px ){
    .main-div-appBar{
        width: 487px;
        height: 70px;
    }
    .help-outer{
        width: 10%;
        padding-top: 2%;
        padding-left: 0%;
        margin-left: 60%;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
    .main-div-appBar{
        width: 100%;
        height: 70px;
    }
    .registration-pane{
        width: 129%;
    }
    .outer-reg-pane {
        margin-top: 21%;
        margin-left: 3.5%;
        width: 93%;
        height: 490px;
        display: block;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
    .logo-img {
        width: 18%;
        height: 6%;
        margin-right: 2%;
    }
    .nav-heading{
        padding-top: 2%;
    }
    .help-outer {
        width: 10%;
        padding-top: 5%;
        padding-left: 0%;
        margin-left: 80%;
    }
    .logout-link{
        font-size: 20px;
    }
    .logout {
        padding-top: 4.5%;
        padding-left: 2%;
        margin-left: 12%;
    }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
and (orientation: landscape){

}
@media only screen and (min-device-width: 411px) and (max-device-width: 823px){
    .main-div-appBar{
        width: 100%;
        height: 70px;
    }
    .logo-img {
        width: 18%;
        height: 6%;
        margin-right: 2%;
    }
    .nav-heading{
        padding-top: 2%;
    }
    .help-outer {
        width: 10%;
        padding-top: 5%;
        padding-left: 0%;
        margin-left: 80%;
    }
    .logout-link{
        font-size: 20px;
    }
    .logout {
        padding-top: 4.5%;
        padding-left: 2%;
        margin-left: 12%;
    }
}
