.main-div-appBar{
    background-color: #6094B7;
    width: 100%;
    height: 75px;
    padding-top: 1%;
    margin-right: 0%;
}
.nav-heading{
    color: white;
    font-size: 32px;
    margin: 0%;
    padding-top: 1%;
    font-weight: bold;
}
.logo-img{
    width: 6%;
    height: 6%;
    margin-right: 2%;
}
.inner-app-bar{
    display: inline-flex;
    margin-left: 4%;
}
.help-outer{
    width: 100%;
    padding-top: 1%;
    padding-left: 80%;
    margin-left: 100%;
}
.help-icon{
    /*icon class*/
}
.logout{
    padding-top: 1.5%;
    padding-left: 2%;
    margin-left: 1%;
}
.logout-link{
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px){

}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px)
and (orientation: landscape){

}

@media (max-width: 768px ) and (min-width: 720px) {

}