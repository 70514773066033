.side-nav{
    background-color: #97BDD9;
    height: 100%;
    width: 290px;
    height: 660px;
}
.sing-nav{
    background-color: #88B2CF;

}

.sidenav---sidenav---_2tBP {
    position: absolute;
    top: 11.4%;
    bottom: 0;
    left: 0;
    z-index: 1006;
    min-width: 64px;
    background: #7eb0c7;
    transition: min-width 0.15s;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
    color: #ffffff;
}